import React from "react";
import PropTypes from "prop-types";

export default function PartnersHeaderComponent(props) {
    return (
        <div>
            <section className="hero-home-section">
                <div className="hero-wrapper-contain">
                    <div
                        data-w-id="9306ff74-f6c3-5268-880e-ae34eec4e2c2"
                        className="hero-home-wrapper new"
                    >
                        <div className="hero-home-text-wrapper new">
                            <div
                                data-w-id="9306ff74-f6c3-5268-880e-ae34eec4e2c4"
                                className="text-block-391"
                            >
                                <div>
                                    Your Trusted Partner for <br/>‍
                                    <span className="text-span-370 text-green-700" style={{color: "#15803d"}}>
                                          Store Credit
                                    </span>
                                </div>
                            </div>
                            <div className="paragraph-18px hero-home-paragraph new">
                                <div className="hero-platform-subheading-text">
                                    Pabloo is the best solution for managing store credit.{" "}
                                    <strong>Over a thousand professional retailers</strong> use
                                    Pabloo to issue store credit, track customer balances, and
                                    nurture customer relationships.{" "}
                                </div>
                            </div>

                        </div>
                    </div>
                    <img
                        loading="lazy"
                        src="https://cdn.prod.website-files.com/61e8ec6584cc457e5ccb9501/652fdb2fc7f49adf2650b86d_Hero%20Bg.svg"
                        alt=""
                        className="hero-bkg"
                    />
                </div>
                {props.children}
            </section>
        </div>
    );
}



export function getIPAddress(callbackFunction) {
    // Fetch IP address using ipify API
    fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((data) => {
            callbackFunction(data.ip)
        })
        .catch((err) => {
            callbackFunction("Unable to fetch IP address");
        });
}
//React imports
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';


//Imports the components

import FooterComponent from '../components/home/FooterComponent.js';
import NavBarComponent from '../components/navBar/NavBarComponent.js';
import HeaderComponent from '../components/HeaderComponent.js';
import {getIPAddress} from "../models/locationHelpers";

//Imports assets such as images
import { sendSlackMessage } from "../models/notifications";


export default function ForwardingPage() {
    useEffect(() => {
        const referrer = document.referrer;
        const currentPath = window.location.href;
        getIPAddress((ipAddress) => {
            sendSlackMessage(`Visitor is visiting the forwarding page. Referrer: ${referrer} - Path: ${currentPath} - IP Address ${ipAddress}`, "#website-updates")
            window.location.href = "https://apps.shopify.com/pabloo-store-credit"
        });

    }, [])

    return (
        <div>
            <div className="updated-page-wrapper">
                <NavBarComponent />
                <div style={{
                    minHeight: "100vh",
                    background: "#faf2eb"
                }}>
                    <HeaderComponent />
                </div>
                <FooterComponent />

            </div>
        </div>
    );
}


import React, {useEffect, useState} from "react";
import CTAButtonLeft from "../generalComponents/LeftctaButton.jsx";
import CTAButtonRight from "../generalComponents/ctaButtonRight.jsx";
import "../../styles/contact/style.css";
import "../../styles/contact/button-center.css";
import { sendSlackMessage } from "../../models/notifications.js";
import {Transition, TransitionChild} from "@headlessui/react";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {getIPAddress} from "../../models/locationHelpers";

export default function FormComponent(props) {
    const [pageIndex, setPageIndex] = useState(-1);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    let navigate = useNavigate();

    useEffect(() => {
        setPageIndex(0)
    }, [])

    const isFormValid =
        fullName.trim() !== "" && email.trim() !== "" && website.trim() !== "";
    return (
        <div>
            <div className="ze ug oe">
                <div className="wi">
                    <form className="ge justify-between">
                        <div className=""
                        >
                            {transitionWrapper(
                                pageIndex === 0,
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        <div>
                                            <label htmlFor="email" className="vf dg jg">
                                                {" "}
                                                Email address{" "}
                                            </label>
                                            <div className="rb">
                                                <input
                                                    type="email_"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Enter your email"
                                                    className="vb lc hf of vf jg ze pe ue ne hh gh jh lh"
                                                    style={{
                                                        color: "#000000",
                                                    }}
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <label htmlFor="website" className="vf dg jg">
                                            Company website
                                        </label>
                                        <div className="rb">
                                            <input
                                                type="url_"
                                                name="website"
                                                id="website"
                                                placeholder="Enter your company's website"
                                                className="vb lc hf of vf jg ze pe ue ne hh gh jh lh"
                                                style={{
                                                    color: "#000000",
                                                }}
                                                value={website}
                                                onChange={(e) => {
                                                    setWebsite(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {transitionWrapper(
                                pageIndex === 1,
                                <div className="space-y-4">

                                    <div>
                                        <label htmlFor="fullName" className="vf dg jg">
                                            {" "}
                                            Your name{" "}
                                        </label>
                                        <div className="rb">
                                            <input
                                                type="text_"
                                                name="fullName"
                                                id="fullName"
                                                placeholder="Enter your full name"
                                                className="vb lc hf of vf jg ze pe ue ne hh gh jh lh"
                                                style={{
                                                    color: "#000000",
                                                }}
                                                value={fullName}
                                                onChange={(e) => {
                                                    setFullName(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/*<div className="">*/}
                                    {/*    <label htmlFor="fullName" className="vf dg jg">*/}
                                    {/*        {" "}*/}
                                    {/*        Message to us{" "} (optional)*/}
                                    {/*    </label>*/}
                                    {/*    <div className="rb">*/}
                                    {/*        <input*/}
                                    {/*            type="text_"*/}
                                    {/*            name="fullName"*/}
                                    {/*            id="fullName"*/}
                                    {/*            placeholder="Enter your full name"*/}
                                    {/*            className="vb lc hf of vf jg ze pe ue ne hh gh jh lh"*/}
                                    {/*            style={{*/}
                                    {/*                color: "#000000",*/}
                                    {/*            }}*/}
                                    {/*            value={fullName}*/}
                                    {/*            onChange={(e) => {*/}
                                    {/*                setFullName(e.target.value);*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                            )}
                            <div>
                                <div id="button-style" className="button-right">
                                    <CTAButtonRight
                                        text={pageIndex === 0 ? "Submit" : "Create account"}
                                        onClick={() => {
                                            if (pageIndex === 1) {
                                                handleSubmission()
                                            } else {
                                                setPageIndex(pageIndex + 1);
                                                getIPAddress((ipAddress) => {
                                                    sendSlackMessage(`🧑‍💻API form submitted Full Name: ${fullName} - Email: ${email} - Website: ${website} - IP Address ${ipAddress}`, "#website-updates")
                                                })
                                            }
                                        }}
                                        isValid={isFormValid}
                                    />
                                </div>
                                {
                                    pageIndex !== 0 &&
                                    <div id="button-style" className="button-left">
                                        <CTAButtonLeft
                                            text="Back"
                                            onClick={() => {
                                                setPageIndex(pageIndex - 1);
                                            }}
                                            isValid={isFormValid}
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );


    function transitionWrapper(shouldDisplay, children) {
        return (
            <div className="">

                { shouldDisplay && children }
                {/*<Transition*/}
                {/*  show={shouldDisplay}*/}
                {/*>*/}
                {/*    <TransitionChild*/}

                {/*        // enter="transition ease-in-out duration-300 transform"*/}
                {/*        // enterFrom="translate-x-full"*/}
                {/*        // enterTo="translate-x-0"*/}
                {/*        // leave="transition ease-in-out duration-300 transform"*/}
                {/*        // leaveFrom="translate-x-0"*/}
                {/*        // leaveTo="-translate-x-full"*/}
                {/*        >*/}

                {/*        <TransitionChild*/}
                {/*            enter="transition-opacity duration-1000"*/}
                {/*            enterFrom="opacity-0"*/}
                {/*            enterTo="opacity-100"*/}
                {/*            leave="transition-opacity duration-100"*/}
                {/*            leaveFrom="opacity-50"*/}
                {/*            leaveTo="opacity-0"*/}
                {/*        >*/}
                {/*            {children}*/}
                {/*        </TransitionChild>*/}
                {/*    </TransitionChild>*/}
                {/*</Transition>*/}
            </div>
        );
    }

    function handleSubmission() {
        if (isFormValid) {
            const myHeaders = new Headers();
            myHeaders.append("accept", "application/json");
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                type: "form_submission",
                name: fullName,
                email: email,
                website: website
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch(
                "https://server.pabloo.com/website/handle_submission",
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);

                    window.gtag("event", "form_submission", {
                        event_category: "Contact Form",
                        event_label: "Submit for review",
                        name: fullName,
                        email: email,
                        website: website,
                    });

                })
                .catch((error) => console.error(error));
        } else {
            alert("Please complete all fields.");
        }
    }
}



FormComponent.propTypes = {
}
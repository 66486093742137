import React, {useEffect} from "react";
import {getIPAddress} from "../models/locationHelpers";
import {sendSlackMessage} from "../models/notifications";
import NavBarComponent from "../components/navBar/NavBarComponent";
import PartnersHomeView from "../components/partners/partnersHomeView";
import FooterComponent from "../components/home/FooterComponent";
import APIPageHomeComponent from "../components/apiComponents/APIPageHomeComponent";


export default function StoreCreditApi() {


    useEffect(() => {
        getIPAddress((ipAddress) => {
            sendSlackMessage(`🧑‍💻$$ Visitor is visiting the API page ${ipAddress}`, "#website-updates")
        })
    }, [])

    return (
        <div>
            <div className="updated-page-wrapper">

                <div style={{
                    minHeight: "100vh",
                    background: "#faf2eb"
                }}>
                    <NavBarComponent />
                    {/*<PartnersHeaderComponent />*/}
                    <APIPageHomeComponent />
                </div>
                <FooterComponent />

            </div>
        </div>
    );
}
import React, { useState, useEffect } from "react";
import {
  CurrencyRetailing,
  CustomerInterface,
  MobileCompatibility,
  PablooOnShopify,
  ShopifyDashboard,
  StoreCreditGif,
  UpsellProduct,
  Woman1,
} from "../../assets/assets";

const TribeSection = () => {
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => resolve(script);
        script.onerror = () =>
          reject(new Error(`Failed to load script ${src}`));
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        await loadScript(
          "https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Ftribe-query.js?alt=media&token=cb0874bb-4c9d-49b9-b392-ea338184edd7"
        );
        await loadScript(
          "https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2FpablooFlow.js?alt=media&token=61b7ddf9-9928-4a1b-b9da-0a4512a2f909"
        );
        setScriptsLoaded(true);
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    loadScripts();

    return () => {
      const jQueryScript = document.querySelector(
        'script[src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Ftribe-query.js?alt=media&token=cb0874bb-4c9d-49b9-b392-ea338184edd7"]'
      );
      const webflowScript = document.querySelector(
        'script[src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2FpablooFlow.js?alt=media&token=61b7ddf9-9928-4a1b-b9da-0a4512a2f909"]'
      );
      if (jQueryScript) jQueryScript.remove();
      if (webflowScript) webflowScript.remove();
    };
  }, []);

  if (!scriptsLoaded) {
    return <div>{/*Loading...*/}</div>;
  }

  return <div>{sectionView()}</div>;

  function sectionView() {
    return (
      <section className="tribe-section">
        <div className="tribe-wrapper">
          <h1 className="heading-40px tribe-heading">
            Streamlining your e-commerce with one platform for{" "}
            <span className="pink-heading-span">all of your store credit.</span>
            {/*Introduce your e-commerce business to <span className="pink-heading-span">the power of store credit Credit </span>*/}
          </h1>
          <div
            data-current="AI"
            data-easing="ease"
            data-duration-in={500}
            data-duration-out={200}
            className="tribe-tabs w-tabs"
          >
            <div className="tribe-tabs-menu w-tab-menu">
              {cellView(
                0,
                "Use credit at checkout",
                "Customers can use store credit to buy products from your website. You have full control over the store credit configuration.",
                () => setSelectedIndex(0)
              )}

              {cellView(
                  1,
                  "Add credit to a customer's account",
                  <div>
                    Customers can receive store credit in several ways. Admins can
                    issue credit for refunds or other needs, and customers can
                    also earn credit through cashback, special offers, upsells, or
                    store credit boosters.
                  </div>,
                  () => setSelectedIndex(1)
              )}

              {cellView(
                2,
                "Process money instantly with $0 processing fees",
                "Businesses use Pabloo because it completely eliminates processing fees, a significant cost for online retailers, and allows for faster money movement.",
                () => setSelectedIndex(2)
              )}
              {cellView(
                3,
                "Integrates with Shopify, WooCommerce, and offers an SDK for developers",
                "The Pabloo technology works with the major e-commerce platforms: Shopify, WooCommerce, and offers a public SDK for full customizability.",
                () => setSelectedIndex(3)
              )}
            </div>
            <div
              style={{
                height: "50%",
                width: "100%",
                maxHeight: "55%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div data-w-tab="AI" className="w-tab-pane w--tab-active">
                {selectedImage()}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function selectedImage() {
    let url = MobileCompatibility;
    let styling = {};
    switch (selectedIndex) {
      case 0:
        // url = CurrencyRetailing;
        url = StoreCreditGif;
        styling = {
          backgroundImage: "linear-gradient(180deg, #014737, #014737)",
        };
        break;
      case 1:
        // url = UpsellProduct;
        url = PablooOnShopify;
        styling = {
          backgroundImage: "linear-gradient(180deg, #014737, #fbeded)",
        };
        break;
      case 2:
        url = CustomerInterface;
        styling = {
          backgroundImage: "linear-gradient(180deg, #fbeded, #014737)",
        };
        break;
      case 3:
        url = ShopifyDashboard;
        styling = {
          backgroundImage: "linear-gradient(135deg, #014737, #014737)",
        };
        break;
      default:
        break;
    }
    return (
      <div className="tribe-tab-image-contain flex justify-center bg-opacity-0">
        <div
          className="flex justify-center shadow-2xl rounded-xl"
          style={{
            width: "90%",
          }}
        >
          <div className="rounded-xl p-4" style={styling}>
            <img src={url} className="rounded-xl" />
          </div>
        </div>
      </div>
    );
  }

  function cellView(index, title, description, onClick) {
    return (
      <div
        data-w-tab={index}
        data-w-id="5e2d1f2f-d69f-16c4-21d3-410030eebd03"
        className="tribe-tab-link w-inline-block w-tab-link"
        style={{
          width: "800px",
        }}
      >
        <div className="tribe-tab-link-info-wrapper">
          <div className="tribe-progress-bar-contain">
            <div className="tribe-tab-progress-bar" />
          </div>
          <div className="tribe-tab-content-wrapper">
            <div className="tribe-tab-top-heading-contain">
              <h2
                className="heading-24px tribe-link-heading cursor-pointer"
                onClick={() => {
                  onClick();
                }}
              >
                {selectedIndex !== index && (
                  <div
                    className="p-4 rounded cursor-pointer"
                    style={{ background: "#FFFFFF" }}
                  >
                    {title}
                  </div>
                )}
              </h2>
            </div>
            {selectedIndex === index && (
              <div className="tribe-tab-headings-contain">
                <div className="">
                  <div className="">
                    <div className=""></div>
                  </div>
                </div>
                <h2 className="heading-24px tribe-link-heading">{title}</h2>
                <div className="paragraph-18px tribe-tab-paragraph">
                  {description}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  function imagesView() {
    return (
      <div>
        <div
          className="tribe-tabs-content w-tab-content"
          style={{
            background: "#FFFFFF",
            height: "500px",
          }}
        >
          <div data-w-tab="Spends" className="w-tab-pane">
            <div className="tribe-tab-image-contain">
              <div className="tribe-tab-background-gradient-contain">
                <div className="tribe-tab-background-gradient" />
              </div>
            </div>
          </div>

          <div data-w-tab="AI" className="w-tab-pane w--tab-active">
            <div className="tribe-tab-image-contain">
              <div className="tribe-tab-background-gradient-contain">
                <div className="tribe-tab-background-gradient" />
              </div>
            </div>
          </div>
          <div data-w-tab="Slack" className="w-tab-pane">
            <div className="tribe-tab-image-contain">
              <div className="tribe-tab-background-gradient-contain">
                <div className="tribe-tab-background-gradient" />
              </div>
              <div className="tribe-image-wrapper">
                <div
                  data-is-ix2-target={1}
                  className="tribe-tab-lottie c"
                  data-w-id="5e2d1f2f-d69f-16c4-21d3-410030eebd27"
                  data-animation-type="lottie"
                  data-loop={0}
                  data-direction={1}
                  data-autoplay={0}
                  data-renderer="svg"
                  data-default-duration="12.016666666666667"
                  data-duration={12}
                />
                <div className="tribe-tab-lottie-shadow" />
              </div>
            </div>
          </div>
          <div data-w-tab="Buying" className="w-tab-pane">
            <div className="tribe-tab-image-contain">
              <div className="tribe-tab-background-gradient-contain">
                <div className="tribe-tab-background-gradient" />
              </div>
              <div className="tribe-image-wrapper">
                <div
                  data-is-ix2-target={1}
                  className="tribe-tab-lottie d"
                  data-w-id="5e2d1f2f-d69f-16c4-21d3-410030eebd2e"
                  data-animation-type="lottie"
                  data-loop={0}
                  data-direction={1}
                  data-autoplay={0}
                  data-renderer="svg"
                  data-default-duration="12.02869487208992"
                  data-duration={12}
                />
                <div className="tribe-tab-lottie-shadow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default TribeSection;

import { Fragment, useEffect, useState } from "react";
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
} from "@headlessui/react";
import PropTypes from "prop-types";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function SelectionViewComponent(props) {
    const [selectedIndex, setSelectedIndex] = useState(undefined);
    const partnershipTypes = [
        {
            title: "Integration",
            slug: "integration",
        },
        {
            title: "Affiliate",
            slug: "affiliate",
        },
        {
            title: "Co-Marketing",
            slug: "co_marketing",
        },
    ];

    function setSelectedIndexWrapper(index) {
        setSelectedIndex(index);
        props.setSelectedPartnershipType(partnershipTypes[index].slug);
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton className="inline-flex w-full justify-between items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <span>
            {selectedIndex !== undefined
                ? `Selected partnership: ${partnershipTypes[selectedIndex].title}`
                : "Select partnership type"}
          </span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="3"
                        stroke="currentColor"
                        className="w-4 h-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                    </svg>
                </MenuButton>
            </div>

            <Transition
                enter="transition ease-out duration-700"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <MenuItem>
                            {({ focus }) => (
                                <div
                                    className={classNames(
                                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                    )}
                                    onClick={() => {
                                        setSelectedIndexWrapper(0);
                                    }}
                                >
                                    Integration
                                </div>
                            )}
                        </MenuItem>
                        <MenuItem>
                            {({ focus }) => (
                                <div
                                    className={classNames(
                                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                    )}
                                    onClick={() => {
                                        setSelectedIndexWrapper(1);
                                    }}
                                >
                                    Affiliate
                                </div>
                            )}
                        </MenuItem>
                        <MenuItem>
                            {({ focus }) => (
                                <div
                                    className={classNames(
                                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                    )}
                                    onClick={() => {
                                        setSelectedIndexWrapper(2);
                                    }}
                                >
                                    Co-Marketing
                                </div>
                            )}
                        </MenuItem>
                    </div>
                </MenuItems>
            </Transition>
        </Menu>
    );
}

SelectionViewComponent.propTypes = {
    setSelectedPartnershipType: PropTypes.func,
};

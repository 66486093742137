// import React, { useEffect } from "react";
import {Man1, TestimonialsJustineTiu, TestimonialJiakeLiu, TestimonialsTaylorBorenstein} from "../../assets/assets";

const TestimonialComponent = () => {
  return (
    <section className="reviews-section">
      <div className="reviews-main-wrapper">
        <div
          data-delay={4000}
          data-animation="slide"
          className="reviews-slider w-slider"
          data-autoplay="false"
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit={0}
          data-nav-spacing={8}
          data-duration={800}
          data-infinite="false"
          id="reviewslider"
        >
          <div className="reviews-slider-mask w-slider-mask">
          </div>
          <div className="slider-arrow left w-slider-arrow-left">
            <div className="review-slide-arrow-icon-embed w-embed">
              <svg
                width={10}
                height={16}
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.75 1.25L2 8L8.75 14.75"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="slider-arrow right w-slider-arrow-right">
            <div className="review-slide-arrow-icon-embed right w-embed">
              <svg
                width={10}
                height={16}
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.75 1.25L2 8L8.75 14.75"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="review-slide-nav w-slider-nav w-round" />
        </div>

        <div className="html-embed-44 w-embed">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".w-slider-dot { background: #E9E6E7; width: 40px; height: 4px; border-radius: 10px; } .w-slider-dot.w-active { background: #240213; } .w-slider-nav.w-round>div { border-radius: 10px; }",
            }}
          />
        </div>
        <div
          data-current="Tab 1"
          data-easing="ease"
          data-duration-in={300}
          data-duration-out={100}
          className="tabs-3 w-tabs"
        >
          <div className="w-tab-content">
            <div
              data-w-tab="Tab 1"
              className="tab-pane-tab-1-3 w-tab-pane w--tab-active"
            >
              <div className="div-block-861">
                <div className="div-block-862">
                  <img
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 216.2890625px, (max-width: 991px) 28vw, (max-width: 1279px) 26vw, 260px"
                    alt="pabloo-testimonial-pricing"
                    src={Man1}
                    loading="lazy"
                    className="image-262"
                  />
                </div>
                <div className="div-block-863">
                  <div className="text-block-387">
                    <span className="text-span-356">Saleh Anas, </span>CEO -{" "}
                    <span
                        className="heading-28px"
                        style={{ color: "#15803d" }}
                    >
                      Yuicery
                    </span>
                  </div>
                  <div className="text-block-387">
                    <span className="text-span-356">
                      <h2 className="text-block-388">
                        Our former refund process caused unexpected customer
                        churn. But ever since we switched to Pabloo, we’ve
                        noticed a significant drop in checkout churn. Plus,
                        Pabloo’s platform has given us{" "}
                        <span
                          className="pink-heading-span text-span-360"
                          style={{ color: "#15803d" }}
                        >
                          the ability to use store credit strategically
                        </span>
                        .
                      </h2>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div data-w-tab="Tab 2" className="w-tab-pane">
              <div className="div-block-861">
                <div className="div-block-862">
                  <img
                    alt="Pabloo Testimonials"
                    src={TestimonialsTaylorBorenstein}
                    loading="lazy"
                    className="image-262"
                  />
                </div>
                <div className="div-block-863">
                  <div className="text-block-387">
                    <span className="text-span-356">Taylor Borenstein, </span>
                    Co-Founder -{" "}
                    <span
                      className="heading-28px"
                      style={{ color: "#15803d" }}
                    >
                      Stakt
                    </span>
                  </div>
                  <div className="text-block-388">
                    We were able to adopt and work with Pabloo in the same day,
                    and were delighted with getting our first savings alongside
                    our onboarding process.{" "}
                    <span
                      className="text-span-360"
                      style={{ color: "#15803d" }}
                    >
                      I highly recommend that any company looking for a Store
                      Credit partner contact Pabloo.
                    </span>{" "}
                    They'll take care of everything while you concentrate on
                    growing your business.
                  </div>
                </div>
              </div>
            </div>
            <div data-w-tab="Tab 3" className="w-tab-pane">
              <div className="div-block-861">
                <div className="div-block-862">
                  <img
                    alt="pabloo-women-testimonials-pricing"
                    src={TestimonialsJustineTiu}
                    loading="lazy"
                    className="image-262"
                  />
                </div>
                <div className="div-block-863">
                  <div className="text-block-387">
                    <span className="text-span-356">Justine Tiu, </span>
                    Co-Founder -{" "}
                    <span
                      className="heading-28px"
                      style={{ color: "#15803d" }}
                    >
                      The Woobles
                    </span>
                  </div>
                  <div className="text-block-388">
                    Pabloo proactively manages our store credit reporting,
                    making our lives so much easier. Compared to similar
                    products we've used in the past,{" "}
                    <span
                      className="text-span-362"
                      style={{ color: "#15803d" }}
                    >
                      the customer experience with Pabloo has been nothing short
                      of delightful
                    </span>
                    . We no longer have to worry about{" "}
                    <span
                      className="text-span-363"
                      style={{ color: "#15803d" }}
                    >
                      getting the best results
                    </span>{" "}
                    - it's all taken care of on auto-pilot!
                  </div>
                </div>
              </div>
            </div>
            <div data-w-tab="Tab 4" className="w-tab-pane">
              <div className="div-block-861">
                <div className="div-block-862">
                  <img
                    alt="Pabloo Testimonials"
                    src={TestimonialJiakeLiu}
                    loading="lazy"
                    className="image-262"
                  />
                </div>
                <div className="div-block-863">
                  <div className="text-block-387">
                    <span className="text-span-356">Jiake Liu, </span>CEO -{" "}
                    <span
                        className="heading-28px"
                        style={{ color: "#15803d" }}
                    >
                      Outer
                    </span>
                  </div>
                  <div className="text-block-388">
                    Pabloo has become an integral part of our team. They’re not
                    just a platform; they’re a reliable partner.{" "}
                    <span
                      className="text-span-361"
                      style={{ color: "#15803d" }}
                    >
                      Their innovative solutions have transformed our business,
                      providing not just store credit but a collaborative
                      efficiency we hadn’t experienced before.
                    </span>{" "}
                    My overall experience with the Pabloo team has been
                    brilliant.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-menu-6 w-tab-menu">


            {/*{cellView(1, "Outer")}*/}
            {/*{cellView(2, "stakt")}*/}
            {/*{cellView(3, "The Woobles")}*/}
            {/*{cellView(4, "i6 Group")}*/}


            <a
                data-w-tab="Tab 1"
                className="testimonial_tab_1 w-inline-block w-tab-link w--current"
            >
              <div>
                <h2
                    className="heading-28px"
                    style={{ color: "#200010", textAlign: "center" }}
                >
                  Yuicery
                </h2>
              </div>
            </a>

            <a
              data-w-tab="Tab 2"
              className="testimonial_tab_1 w-inline-block w-tab-link"
            >
              <h2
                  className="heading-28px"
                  style={{ color: "#200010", textAlign: "center" }}
              >
                Stakt
              </h2>
            </a>

            <a
              data-w-tab="Tab 3"
              className="testimonial_tab_1 w-inline-block w-tab-link"
            >
              <h2
                  className="heading-28px"
                  style={{ color: "#200010", textAlign: "center" }}
              >
                The Woobles
              </h2>
            </a>
            <a
              data-w-tab="Tab 4"
              className="testimonial_tab_1 w-inline-block w-tab-link"
            >
              <h2
                  className="heading-28px"
                  style={{ color: "#200010", textAlign: "center" }}
              >
                Outer
              </h2>
            </a>
          </div>
        </div>

        {/* mobile view */}
        <div
          data-delay={4000}
          data-animation="slide"
          className="slider-22 w-slider"
          data-autoplay="true"
          data-easing="ease"
          data-hide-arrows="false"
          data-disable-swipe="false"
          data-autoplay-limit={0}
          data-nav-spacing={3}
          data-duration={500}
          data-infinite="true"
        >
          <div className="mask-10 w-slider-mask">
            {/* Slide 1 */}
            <div className="slide-23 w-slide">
              <div className="div-block-861 slider">
                <div className="div-block-862">
                  <img
                    sizes="(max-width: 479px) 68vw, 100vw"
                    alt="pabloo-testimonial-pricing"
                    src={Man1}
                    loading="lazy"
                    className="image-262"
                  />
                </div>
                <div className="div-block-863">
                  <div className="text-block-387">
                    <span className="text-span-356">Saleh Anas, </span>CEO -{" "}
                    <span
                      className="heading-28px"
                      style={{ color: "#15803d" }}
                    >
                      Yuicery
                    </span>
                  </div>
                  <div className="text-block-387">
                    <span className="text-span-356">
                      <h2 className="text-block-388">
                        Our former refund process caused unexpected customer
                        churn. But ever since we switched to Pabloo, we’ve
                        noticed a significant drop in checkout churn. Plus,
                        Pabloo’s platform has given us{" "}
                        <span
                          className="pink-heading-span text-span-360"
                          style={{ color: "#15803d" }}
                        >
                          the ability to use store credit strategically
                        </span>
                        .
                      </h2>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Slide 2 */}
            <div className="slide-23 w-slide">
              <div className="div-block-861">
                <div className="div-block-862">
                  <img
                    sizes="(max-width: 479px) 69vw, 100vw"
                    alt="Pabloo Testimonials"
                    src={TestimonialsTaylorBorenstein}
                    loading="lazy"
                    className="image-262"
                  />
                </div>
                <div className="div-block-863">
                  <div className="text-block-387">
                    <span className="text-span-356">Taylor Borenstein, </span>
                    Co-Founder -{" "}
                    <span
                      className="heading-28px"
                      style={{ color: "#15803d" }}
                    >
                      Stakt
                    </span>
                  </div>
                  <div className="text-block-388">
                    We were able to adopt and work with Pabloo in the same day,
                    and were delighted with getting our first savings alongside
                    our onboarding process.{" "}
                    <span
                      className="text-span-360"
                      style={{ color: "#15803d" }}
                    >
                      I highly recommend that any company looking for a Store
                      Credit partner contact Pabloo.
                    </span>{" "}
                    They'll take care of everything while you concentrate on
                    growing your business.
                  </div>
                </div>
              </div>
            </div>
            {/* Slide 3 */}
            <div className="slide-23 w-slide">
              <div className="div-block-861">
                <div
                  className="div-block-862">
                  <img
                    sizes="(max-width: 479px) 69vw, 100vw"
                    alt="pabloo-women-testimonials-pricing"
                    src={TestimonialsJustineTiu}
                    loading="lazy"
                    className="image-262"
                  />
                </div>
                <div className="div-block-863">
                  <div className="text-block-387">
                    <span className="text-span-356">Justine Tiu, </span>
                    Co-Founder -{" "}
                    <span
                      className="heading-28px"
                      style={{ color: "#15803d" }}
                    >
                      The Woobles
                    </span>
                  </div>
                  <div className="text-block-388">
                    Pabloo proactively manages our store credit reporting,
                    making our lives so much easier. Compared to similar
                    products we've used in the past,{" "}
                    <span
                      className="text-span-362"
                      style={{ color: "#15803d" }}
                    >
                      the customer experience with Pabloo has been nothing short
                      of delightful
                    </span>
                    . We no longer have to worry about{" "}
                    <span
                      className="text-span-363"
                      style={{ color: "#15803d" }}
                    >
                      getting the best results
                    </span>{" "}
                    - it's all taken care of on auto-pilot!
                  </div>
                </div>
              </div>
            </div>
            {/* Slide 4 */}
            <div className="slide-23 w-slide">
              <div className="div-block-861">
                <div className="div-block-862">
                  <img
                    alt="Pabloo Testimonials Success Story"
                    src={TestimonialJiakeLiu}
                    loading="lazy"
                    className="image-262"
                  />
                </div>
                <div className="div-block-863">
                  <div className="text-block-387">
                    <span className="text-span-356">Jiake Liu, </span>CEO -{" "}
                    <span
                        className="heading-28px"
                        style={{ color: "#15803d" }}
                    >
                      Outer
                    </span>
                  </div>
                  <div className="text-block-388">
                    Pabloo has become an integral part of our team. They’re not
                    just a platform; they’re a reliable partner.{" "}
                    <span
                      className="text-span-361"
                      style={{ color: "#15803d" }}
                    >
                      Their innovative solutions have transformed our business,
                      providing not just store credit but a collaborative
                      efficiency we hadn’t experienced before.
                    </span>{" "}
                    My overall experience with the Pabloo team has been
                    brilliant.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Navigation Arrows */}
          <div className="slider-arrow testimonials left w-slider-arrow-left">
            <div className="review-slide-arrow-icon-embed left w-embed">
              <svg
                width={10}
                height={16}
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.75 1.25L2 8L8.75 14.75"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="slider-arrow testimonials right w-slider-arrow-right">
            <div className="review-slide-arrow-icon-embed right w-embed">
              <svg
                width={10}
                height={16}
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.75 1.25L2 8L8.75 14.75"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="slide-nav-9 w-slider-nav w-round w-num" />
        </div>
      </div>
    </section>
  );


  function cellView(body) {
    return (
        <a
            data-w-tab="Tab 1"
            className="testimonial_tab_1 w-inline-block w-tab-link w--current"
        >
          <div>
            <h2
                className="heading-28px"
                style={{ color: "#200010", textAlign: "center" }}
            >
              {body}
            </h2>
          </div>
        </a>
    )
  }
};

export default TestimonialComponent;

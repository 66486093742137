//React imports
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';

//Imports the components
import FooterComponent from '../components/home/FooterComponent.js';
import NavBarComponent from '../components/navBar/NavBarComponent.js';
import {sendSlackMessage} from "../models/notifications";
import PartnersHomeView from "../components/partners/partnersHomeView";
import PartnersHeaderComponent from "../components/partners/partnersHeaderComponent";
import {getIPAddress} from "../models/locationHelpers";



export default function Partners() {
    useEffect(() => {

        getIPAddress((ipAddress) => {
            sendSlackMessage(`Visitor is visiting the partners page ${ipAddress}`, "#website-updates")
        })
    }, [])

    return (
        <div>
            <div className="updated-page-wrapper">

                <div style={{
                    minHeight: "100vh",
                    background: "#faf2eb"
                }}>
                    <NavBarComponent />
                    {/*<PartnersHeaderComponent />*/}
                    <PartnersHomeView />
                </div>
                <FooterComponent />

            </div>
        </div>
    );
}


import React, {useEffect, useState} from "react";
import {Transition} from "@headlessui/react";
import FormComponent from "./formComponent";


export default function APIPageHomeComponent() {
    const [shouldDisplay, setShouldDisplay] = useState(false)  // For the form
    useEffect(() => {
        setShouldDisplay(true)
    }, [])

    return (
        <div>
            {mainView()}
        </div>
    )

    function mainView() {
        return (
            <div>
                <div className="main-wrapper">
                    <div
                        className="div-block-737"
                        style={{
                            background: "#faf2eb",
                        }}
                    >
                        <div className="div-block-738">
                            <div className="columns w-row">
                                <div className="w-clearfix w-col w-col-7 w-col-stack">
                                    <div>
                                        <h1 className="heading center">
                                          <span>
                                            Start Using the
                                          </span>&nbsp;
                                            <span className="text-span" style={{ color: "#15803d" }}>
                                                Pabloo Store Credit API
                                            </span>&nbsp;Today
                                        </h1>
                                        <div className="demo-sub-heading">
                                            <p className="paragraph-152">
                                                Make fast, compliant financial decisions with our all-in-one store credit data-as-a-service solution.
                                                Issue Store Credit based on custom, pre-defined events to strengthen your tech stack and drive deeper customer engagement.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="div-block-8">
                                        <h3 className="heading-3">
                                            <strong>Trusted by amazing companies worldwide</strong>
                                            <br />
                                        </h3>
                                    </div>
                                    <div className="div-block-740">
                                        <div>
                                            <div className="div-block-741">
                                                <div className="w-layout-grid demo-pointer-wrapper">
                                                    <div id="w-node-cb9eac57-6c2e-1b49-a5ec-768f4d74754d-93cb7768">
                                                        <img
                                                            src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/653f2d7f786750c37805e788_Group%202085663392.svg"
                                                            loading="lazy"
                                                            alt=""
                                                            height={24}
                                                            className="demo-pointer-icon"
                                                        />
                                                    </div>
                                                    <div id="w-node-cb9eac57-6c2e-1b49-a5ec-768f4d74754f-93cb7768">
                                                        <div className="text-block-6">Up to 64% savings</div>
                                                    </div>
                                                </div>
                                                <div className="w-layout-grid demo-pointer-wrapper">
                                                    <div id="w-node-cb9eac57-6c2e-1b49-a5ec-768f4d747553-93cb7768">
                                                        <img
                                                            src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/653f2d97c343f1ecd2f4d7ec_Group%202085663392%20(1).svg"
                                                            loading="lazy"
                                                            alt=""
                                                            height={22}
                                                            className="demo-pointer-icon"
                                                        />
                                                    </div>
                                                    <div id="w-node-cb9eac57-6c2e-1b49-a5ec-768f4d747555-93cb7768">
                                                        <div className="text-block-6">
                                                            $0 processing fees <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-layout-grid demo-pointer-wrapper">
                                                    <div id="w-node-cb9eac57-6c2e-1b49-a5ec-768f4d74755b-93cb7768">
                                                        <img
                                                            src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/653f2dba49859d6302d95614_Group%202085663392%20(3).svg"
                                                            loading="lazy"
                                                            width={24}
                                                            height={22}
                                                            alt=""
                                                            className="demo-pointer-icon"
                                                        />
                                                    </div>
                                                    <div id="w-node-cb9eac57-6c2e-1b49-a5ec-768f4d74755d-93cb7768">
                                                        <div className="text-block-6">Store credit upsells</div>
                                                    </div>
                                                </div>
                                                <div className="w-layout-grid demo-pointer-wrapper">
                                                    <div id="w-node-cb9eac57-6c2e-1b49-a5ec-768f4d747561-93cb7768">
                                                        <img
                                                            src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/653f2da98ef844360d0748de_Group%202085663392%20(2).svg"
                                                            loading="lazy"
                                                            alt=""
                                                            height={22}
                                                            className="demo-pointer-icon"
                                                        />
                                                    </div>
                                                    <div id="w-node-cb9eac57-6c2e-1b49-a5ec-768f4d747563-93cb7768">
                                                        <div className="text-block-6">
                                                            Instant money transfers
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-col w-col-5 w-col-stack">
                                    {handleTransition()}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    function handleTransition() {
        return (
            <div>
                <Transition
                    show={shouldDisplay}
                    enter="transition-opacity duration-1000"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-100"
                    leaveFrom="opacity-50"
                    leaveTo="opacity-0"
                >

                    <div className="">
                        <div className="padding custom-div shadow-2xl" style={{
                            // height: '50vh'
                        }}>
                            <FormComponent />
                        </div>
                    </div>
                </Transition>
            </div>
        )
    }
}